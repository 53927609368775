import Helmet from "react-helmet"
import React from "react"
import LpScripts from "@tightrope/lpscripts"

export default function HomepageLayout({children}) {
  return(
    <section>
      {children}
      <LpScripts></LpScripts>
    </section>
  )
}
